import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Slider from 'react-rangeslider'

const FiveFive = ({
    addExtendFamily5,
    setExtendFamily5,
    memberRelation5,
    setMemberRelation5,
    relationship5,
    setRelationship5,
    name5,
    setName5,
    relationSurname5,
    setRelationSurname5,
    title5,
    setTitle5,
    idNumber5,
    setIdNumber5,
    dateOfBirth5,
    setDateOfBirth5,
    age5,
    setAge5,
    priceValue5,
    setPriceValue5,
    memberRelationship,
    childrenRelationship,
    parentRelationship,
    grandParentsRelationship,
    otherRelationship
}) => {

    const [showNext, setShowNext] = useState(false);
    const [error, setError] = useState('');
    const [link, setLink] = useState('');
    const [step, setStep] = useState(5000);

    const validateID = (id) => {
        if (id) {
            return typeof (id) != "boolean" && !isNaN(id)
        }
    }

    const changePrice = (e) => {
        if(e >= 30000) setStep(10000);
        else setStep(5000);
        setPriceValue5(e);
    }

    const price = {
        padding: '20px 20px'
    }

    const requiredSubmitted = () => {
        if (addExtendFamily5 === 'Yes') {
            if (memberRelation5 !== memberRelationship[0].value && memberRelation5 != '') {
                if (name5 != '') {
                    if (relationSurname5 != '') {
                        if (title5 != '') {
                            if (idNumber5 != '') {
                                if (validateID(idNumber5)) {
                                    if (dateOfBirth5 != '') {
                                        if (age5 != '') {
                                            if (priceValue5 != '0' && priceValue5 != '') {
                                                if (memberRelation5 === 'Additional Spouse' || relationship5 !== 'Choose Relationship' && relationship5 != '') {
                                                    setShowNext(true);
                                                    setLink('/5.6');
                                                    setError('Please check that all info is correct then click next');
                                                } else setError('You have not made a selection of Relationship');
                                            } else setError('You have not submitted Amount for person');
                                        } else setError('You have not submitted your Age');
                                    } else setError('You have not submitted your Date of Birth');
                                } else setError('You have not given a valid numerical value for your ID Number');
                            } else setError('You have not submitted your ID Number');
                        } else setError('You have not submitted your Title');
                    } else setError('You have not submitted your Surname');
                } else setError('You have not submitted your Name');
            } else setError('You have not made a selection of Family Member Relationship');
        } else {
            setLink('/6');
            setShowNext(true)
            setError('Please check that all info is correct then click next')
        }
    }

    return (
        <div className="page">
            <h6 style={{ fontWeight: '600', marginTop: '25px', textAlign: 'center', color: '#BB1A1B', marginBottom: '25px' }}>5 (5). Add Another Extended Family</h6>
            <Form>
                <Form.Group className='mb-2' controlId='addExtendedFamily'>
                    <Form.Label className='Label' >Do you want to add another extended family members? </Form.Label>
                    <Form.Select value={addExtendFamily5} onChange={(e) => setExtendFamily5(e.target.value)}>
                        <option>{"No"}</option>
                        <option>{"Yes"}</option>
                    </Form.Select>
                </Form.Group>
                {addExtendFamily5 === 'Yes' &&
                    <Form.Group className='mb-2' controlId='memberRelation'>
                        <Form.Label className='Label'>*Extended Family Members Relationship </Form.Label>
                        <Form.Select value={memberRelation5} onChange={(e) => setMemberRelation5(e.target.value)}>
                            {memberRelationship.map(({ value, label }, index) =>
                                <option key={value} value={value} >{label}</option>)}
                        </Form.Select>
                    </Form.Group>
                }
                {addExtendFamily5 === 'Yes' && memberRelation5 && memberRelation5 !== memberRelationship[0].value && memberRelation5 !== memberRelationship[1].value &&
                    <Form.Group className='mb-2' controlId='relationship'>
                        <Form.Label className='Label'>*Relationship </Form.Label>
                        {memberRelation5 === 'Children (Own)' &&
                            <Form.Select value={relationship5} onChange={(e) => setRelationship5(e.target.value)}>
                                {childrenRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                        {memberRelation5 === 'Parents' &&
                            <Form.Select value={relationship5} onChange={(e) => setRelationship5(e.target.value)}>
                                {parentRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                        {memberRelation5 === 'Grandparents' &&
                            <Form.Select value={relationship5} onChange={(e) => setRelationship5(e.target.value)}>
                                {grandParentsRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                        {memberRelation5 === 'Other Extended Family' &&
                            <Form.Select value={relationship5} onChange={(e) => setRelationship5(e.target.value)}>
                                {otherRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                    </Form.Group>
                }
                {addExtendFamily5 === 'Yes' && memberRelation5 !== memberRelationship[0].value && memberRelation5 !== '' &&
                    <>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Name</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={name5} onChange={(e) => setName5(e.target.value)} />
                        </Form.Group>

                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Surname</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={relationSurname5} onChange={(e) => setRelationSurname5(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Title</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={title5} onChange={(e) => setTitle5(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*ID Number</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={idNumber5} onChange={(e) => setIdNumber5(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Date of Birth</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={dateOfBirth5} onChange={(e) => setDateOfBirth5(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Age</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={age5} onChange={(e) => setAge5(e.target.value)} />
                        </Form.Group>
                    </>
                }
                {age5 && <Form.Group className='mb-3' controlId='name'>
                    <Form.Label className='Label'>*Please Scale Cover Amount for person</Form.Label>
                    <div style={price}>
                        <Slider
                            min={0}
                            max={100000}
                            step={step}
                            value={priceValue5}
                            onChange={(e) => changePrice(e)} />
                        <div className='value'>{priceValue5}</div>
                    </div>
                </Form.Group>
                }
            </Form>
            <div style={{ textAlign: 'center', padding: '2vw' }}><h6 style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>{error}</h6></div>
            <div style={{ textAlign: 'center' }}>
                <Link to='/5.4'>
                    <Button variant='secondary w-25' style={{ fontWeight: '600', background: '#D0D0D0', border: 'none', float: 'left', marginTop: '10vw', marginLeft: '20vw', marginBottom: '10vh' }}>BACK</Button>
                </Link>
                {showNext && <Link to={link}>
                    <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginTop: '10vw', marginRight: '20vw', marginBottom: '10vh' }} >NEXT</Button>
                </Link>}
                {!showNext && <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginTop: '10vw', marginRight: '20vw', marginBottom: '10vh' }} onClick={requiredSubmitted} >Submit</Button>}
            </div>
        </div>
    )
};

export default FiveFive

