import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Slider from 'react-rangeslider'

const FiveTwo = ({
    addExtendFamily2,
    setExtendFamily2,
    memberRelation2,
    setMemberRelation2,
    relationship2,
    setRelationship2,
    name2,
    setName2,
    relationSurname2,
    setRelationSurname2,
    title2,
    setTitle2,
    idNumber2,
    setIdNumber2,
    dateOfBirth2,
    setDateOfBirth2,
    age2,
    setAge2,
    priceValue2,
    setPriceValue2,
    memberRelationship,
    childrenRelationship,
    parentRelationship,
    grandParentsRelationship,
    otherRelationship
}) => {

    const [showNext, setShowNext] = useState(false);
    const [error, setError] = useState('');
    const [link, setLink] = useState('');
    const [step, setStep] = useState(5000);

    const validateID = (id) => {
        if (id) {
            return typeof (id) != "boolean" && !isNaN(id)
        }
    }

    const price = {
        padding: '20px 20px'
    }

    const changePrice = (e) => {
        if(e >= 30000) setStep(10000);
        else setStep(5000);
        setPriceValue2(e);
    }

    const requiredSubmitted = () => {
        if (addExtendFamily2 === 'Yes') {
            if (memberRelation2 !== memberRelationship[0].value && memberRelation2 != '') {
                if (name2 != '') {
                    if (relationSurname2 != '') {
                        if (title2 != '') {
                            if (idNumber2 != '') {
                                if (validateID(idNumber2)) {
                                    if (dateOfBirth2 != '') {
                                        if (age2 != '') {
                                            if (priceValue2 != '0' && priceValue2 != '') {
                                                if (memberRelation2 === 'Additional Spouse' || relationship2 !== 'Choose Relationship' && relationship2 != '') {
                                                    setShowNext(true);
                                                    setLink('/5.3');
                                                    setError('Please check that all info is correct then click next');
                                                } else setError('You have not made a selection of Relationship');
                                            } else setError('You have not submitted Amount for person');
                                        } else setError('You have not submitted your Age');
                                    } else setError('You have not submitted your Date of Birth');
                                } else setError('You have not given a valid numerical value for your ID Number');
                            } else setError('You have not submitted your ID Number');
                        } else setError('You have not submitted your Title');
                    } else setError('You have not submitted your Surname');
                } else setError('You have not submitted your Name');
            } else setError('You have not made a selection of Family Member Relationship');
        } else {
            setLink('/6');
            setShowNext(true)
            setError('Please check that all info is correct then click next')
        }
    }

    return (
        <div className="page">
            <h6 style={{ fontWeight: '600', marginTop: '25px', textAlign: 'center', color: '#BB1A1B', marginBottom: '25px' }}>5 (2). Add Another Extended Family</h6>
            <Form>
                <Form.Group className='mb-2' controlId='addExtendedFamily'>
                    <Form.Label className='Label' >Do you want to add another extended family members? </Form.Label>
                    <Form.Select value={addExtendFamily2} onChange={(e) => setExtendFamily2(e.target.value)}>
                        <option>{"No"}</option>
                        <option>{"Yes"}</option>
                    </Form.Select>
                </Form.Group>
                {addExtendFamily2 === 'Yes' &&
                    <Form.Group className='mb-2' controlId='memberRelation'>
                        <Form.Label className='Label'>*Extended Family Members Relationship </Form.Label>
                        <Form.Select value={memberRelation2} onChange={(e) => setMemberRelation2(e.target.value)}>
                            {memberRelationship.map(({ value, label }, index) =>
                                <option key={value} value={value} >{label}</option>)}
                        </Form.Select>
                    </Form.Group>
                }
                {addExtendFamily2 === 'Yes' && memberRelation2 && memberRelation2 !== memberRelationship[0].value && memberRelation2 !== memberRelationship[1].value &&
                    <Form.Group className='mb-2' controlId='relationship'>
                        <Form.Label className='Label'>*Relationship </Form.Label>
                        {memberRelation2 === 'Children (Own)' &&
                            <Form.Select value={memberRelation2} value={relationship2} onChange={(e) => setRelationship2(e.target.value)}>
                                {childrenRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                        {memberRelation2 === 'Parents' &&
                            <Form.Select value={memberRelation2} onChange={(e) => setRelationship2(e.target.value)}>
                                {parentRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                        {memberRelation2 === 'Grandparents' &&
                            <Form.Select value={memberRelation2} onChange={(e) => setRelationship2(e.target.value)}>
                                {grandParentsRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                        {memberRelation2 === 'Other Extended Family' &&
                            <Form.Select value={memberRelation2} onChange={(e) => setRelationship2(e.target.value)}>
                                {otherRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                    </Form.Group>
                }
                {addExtendFamily2 === 'Yes' && memberRelation2 !== memberRelationship[0].value && memberRelation2 !== '' &&
                    <>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Name</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={name2} onChange={(e) => setName2(e.target.value)} />
                        </Form.Group>

                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Surname</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={relationSurname2} onChange={(e) => setRelationSurname2(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Title</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={title2} onChange={(e) => setTitle2(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*ID Number</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={idNumber2} onChange={(e) => setIdNumber2(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Date of Birth</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={dateOfBirth2} onChange={(e) => setDateOfBirth2(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Age</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={age2} onChange={(e) => setAge2(e.target.value)} />
                        </Form.Group>
                    </>
                }
                {age2 && <Form.Group className='mb-3' controlId='name'>
                    <Form.Label className='Label'>*Please Scale Cover Amount for person</Form.Label>
                    <div style={price}>
                        <Slider
                            min={0}
                            max={100000}
                            step={step}
                            value={priceValue2}
                            onChange={(e) => changePrice(e)} />
                        <div className='value'>{priceValue2}</div>
                    </div>
                </Form.Group>
                }
            </Form>
            <div style={{ textAlign: 'center', padding: '2vw' }}><h6 style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>{error}</h6></div>
            <div style={{ textAlign: 'center' }}>
                <Link to='/5'>
                    <Button variant='secondary w-25' style={{ fontWeight: '600', background: '#D0D0D0', border: 'none', float: 'left', marginTop: '10vw', marginLeft: '20vw', marginBottom: '10vh' }}>BACK</Button>
                </Link>
                {showNext && <Link to={link}>
                    <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginTop: '10vw', marginRight: '20vw', marginBottom: '10vh' }} >NEXT</Button>
                </Link>}
                {!showNext && <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginTop: '10vw', marginRight: '20vw', marginBottom: '10vh' }} onClick={requiredSubmitted} >Submit</Button>}
            </div>
        </div>
    )
};

export default FiveTwo

