import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import Slider from 'react-rangeslider'

const Five = ({
    addExtendFamily,
    setExtendFamily,
    memberRelation,
    setMemberRelation,
    relationship,
    setRelationship,
    name,
    setName,
    relationSurname,
    setRelationSurname,
    title,
    setTitle,
    idNumber,
    setIdNumber,
    dateOfBirth,
    setDateOfBirth,
    age,
    setAge,
    priceValue,
    setPriceValue,
    memberRelationship,
    childrenRelationship,
    parentRelationship,
    grandParentsRelationship,
    otherRelationship
}) => {

    const [showNext, setShowNext] = useState(false);
    const [error, setError] = useState('');
    const [link, setLink] = useState('');
    const [step, setStep] = useState(5000);

    const validateID = (id) => {
        if (id) {
            return typeof (id) != "boolean" && !isNaN(id)
        }
    }

    const price = {
        padding: '20px 20px'
    }

    const changePrice = (e) => {
        if(e >= 30000) setStep(10000);
        else setStep(5000);
        setPriceValue(e);
    }

    const requiredSubmitted = () => {
        if (addExtendFamily === 'Yes') {

            if (memberRelation !== memberRelationship[0].value && memberRelation != '') {
                if (name != '') {
                    if (relationSurname != '') {
                        if (title != '') {
                            if (idNumber != '') {
                                if (validateID(idNumber)) {
                                    if (dateOfBirth != '') {
                                        if (age != '') {
                                            if (priceValue != '0' && priceValue != '') {
                                                if (memberRelation === 'Additional Spouse' || relationship !== 'Choose Relationship' && relationship != '') {
                                                    setShowNext(true);
                                                    setLink('/5.2');
                                                    setError('Please check that all info is correct then click next');
                                                } else setError('You have not made a selection of Relationship');
                                            } else setError('You have not submitted Amount for person');
                                        } else setError('You have not submitted your Age');
                                    } else setError('You have not submitted your Date of Birth');
                                } else setError('You have not given a valid numerical value for your ID Number');
                            } else setError('You have not submitted your ID Number');
                        } else setError('You have not submitted your Title');
                    } else setError('You have not submitted your Surname');
                } else setError('You have not submitted your Name');
            } else setError('You have not made a selection of Family Member Relationship');
        } else {
            setLink('/6');
            setShowNext(true)
            setError('Please check that all info is correct then click next')
        }
    } 

    return (
        <div className="page">
            <h6 style={{ fontWeight: '600', marginTop: '25px', textAlign: 'center', color: '#BB1A1B', marginBottom: '25px' }}>5. Extended Family</h6>
            <Form>
                <Form.Group className='mb-2' controlId='addExtendedFamily'>
                    <Form.Label className='Label' >Do you want to add extended family members? </Form.Label>
                    <Form.Select value={addExtendFamily} onChange={(e) => setExtendFamily(e.target.value)}>
                        <option>{"No"}</option>
                        <option>{"Yes"}</option>
                    </Form.Select>
                </Form.Group>

                {addExtendFamily === 'Yes' &&
                    <Form.Group className='mb-2' controlId='memberRelation'>
                        <Form.Label className='Label'>*Extended Family Members Relationship </Form.Label>
                        <Form.Select value={memberRelation} onChange={(e) => setMemberRelation(e.target.value)}>
                            {memberRelationship.map(({ value, label }, index) =>
                                <option key={value} value={value} >{label}</option>)}
                        </Form.Select>
                    </Form.Group>
                }
                {addExtendFamily === 'Yes' && memberRelation && memberRelation !== memberRelationship[0].value && memberRelation !== memberRelationship[1].value &&
                    <Form.Group className='mb-2' controlId='relationship'>
                        <Form.Label className='Label'>*Relationship </Form.Label>
                        {memberRelation === 'Children (Own)' &&
                            <Form.Select value={relationship} onChange={(e) => setRelationship(e.target.value)}>
                                {childrenRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                        {memberRelation === 'Parents' &&
                            <Form.Select value={relationship} onChange={(e) => setRelationship(e.target.value)}>
                                {parentRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                        {memberRelation === 'Grandparents' &&
                            <Form.Select value={relationship} onChange={(e) => setRelationship(e.target.value)}>
                                {grandParentsRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                        {memberRelation === 'Other Extended Family' &&
                            <Form.Select value={relationship} onChange={(e) => setRelationship(e.target.value)}>
                                {otherRelationship.map(({ value, label }, index) =>
                                    <option key={value} value={value} >{label}</option>)}
                            </Form.Select>
                        }
                    </Form.Group>
                }

                {addExtendFamily === 'Yes' && memberRelation !== memberRelationship[0].value && memberRelation !== '' &&
                    <>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Name</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>

                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Surname</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={relationSurname} onChange={(e) => setRelationSurname(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Title</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={title} onChange={(e) => setTitle(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*ID Number</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={idNumber} onChange={(e) => setIdNumber(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Date of Birth</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label className='Label'>*Age</Form.Label>
                            <Form.Control className='Control' type='text' placeholder={age} onChange={(e) => setAge(e.target.value)} />
                        </Form.Group>
                    </>
                }
            </Form>
            {age && <Form.Group className='mb-3' controlId='name'>
                <Form.Label className='Label'>*Please Scale Cover Amount for person</Form.Label>
                <div style={price}>
                    <Slider
                        min={0}
                        max={100000}
                        step={step}
                        value={priceValue}
                        onChange={(e) => changePrice(e)} />
                    <div className='value'>{priceValue}</div>
                </div>
            </Form.Group>
            }
            <div style={{ textAlign: 'center', padding: '2vw' }}><h6 style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>{error}</h6></div>
            <div style={{ textAlign: 'center' }}>
                <Link to='/4'>
                    <Button variant='secondary w-25' style={{ fontWeight: '600', background: '#D0D0D0', border: 'none', float: 'left', marginTop: '10vw', marginLeft: '20vw', marginBottom: '10vh' }}>BACK</Button>
                </Link>
                {showNext && <Link to={link}>
                    <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginTop: '10vw', marginRight: '20vw', marginBottom: '10vh' }} >NEXT</Button>
                </Link>}
                {!showNext && <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginTop: '10vw', marginRight: '20vw', marginBottom: '10vh' }} onClick={requiredSubmitted} >Submit</Button>}
            </div>
        </div>
    )
};

export default Five

